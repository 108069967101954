<template>
  <div class="posting-accordian">
    <!-- <template>  v-if="postings.length > 0" -->

      <div class="accordion text-left" id="accordionExample">
        <div class="card" v-for="(post, index) in postings" :key="post.id">
          <div
            class="card-header"
            :id="`heading${index}`"
            data-toggle="collapse"
            :data-target="`#collapse${index}`"
            :aria-expanded="(index == 0) ? 'true': 'false'"
            :aria-controls="`collapse${index}`"
          >
            <a class="btn btn-link" :class="(index == 0) ? '' : 'collapsed'">
              <h3 class="title">{{ post.enTitle }}</h3>
            </a>

            <div class="float-right text-right">
              <div class="card-header-description">
                <p class="department">All</p> <!-- {{ post.department ? departmentsById[post.department].enName : 'None' }} -->
                <p class="location">Singapore</p> <!-- {{ post.location ? locationsById[post.location].enName : 'None' }} -->
              </div>
              <img class="icon plus" src="../../assets/img/plus.svg">
              <router-link
                tag="button"
                v-if="admin"
                class="btn func-btn edit"
                :to="`/admin/careers/${post.id}`"
              ></router-link>
              <Modal
                :id="`delete${index}`"
                title="Confirm Delete?"
                type="delete"
                class="func-btn"
                content="Are you sure you want to delete this post? <br>This action cannot be undone and all information entered will be permanently deleted."
                primaryBtnText="Delete"
                @submit="onDelete(post.id)"
                v-if="admin"
              />
            </div>
          </div>

          <div
            :id="`collapse${index}`"
            class="collapse"
            :class="(index == 0) ? 'show': ''"
            :aria-labelledby="`heading${index}`"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <h4 class="secondary-title">Roles and Responsibilities:</h4>
              <p class="paragraph mb60" v-html="post.enDescription"></p>
              <h4 class="secondary-title">Requirements:</h4>
              <p class="paragraph mb60" v-html="post.enRequirements"></p>
              <a href="mailto:carol.wee@aezypay.com" target="_blank" v-if="!admin" class="btn btn-apply">{{ $t('openPositions.applyNow') }}</a>
            </div>
          </div>
        </div>
      </div>
    <!-- </template> -->

    <!-- <template v-else>
      <img class="no-open-position" src="../../assets/img/careers/career-no-open-position.svg">
    </template> -->
  </div>
</template>

<script>
import Modal from "@/components/admin/Modal.vue";
import axios from "axios";
import $ from 'jquery';
import i18n from '@/i18n';

export default {
  name: "open-position-accordian",
  props: {
    admin: Boolean, 
    departments: {
      required: true, 
      type: Array
    },
    locations: {
      required: true, 
      type: Array
    },
  },
  components: {
    Modal
  },
  computed: {
    departmentsById(department) {
        return this.departments.reduce((acc, dep) => {
        let { id } = dep
        return {
          ...acc, 
          [id]: dep
        }
      }, {})
    },
    locationsById(location){
      return this.locations.reduce((acc, loc)=> {
        let { id } =loc
        return {
          ...acc, 
          [id]: loc
        }
      }, {})
    },
  },
  // watch: {
  //   language: function(val) {
  //     this.languageValues.name = val + 'Name';
  //     this.languageValues.title = val + 'Title';
  //     this.languageValues.description = val + 'Description';
  //     this.languageValues.requirements = val + 'Requirements';
  //   },
  // },
  data: function() {
    return {
      languageValues: {
        name: 'enName',
        title: 'enTitle',
        description: 'enDescription',
        requirements: 'enRequirements',
      },
      postings: [
        {
          id: '9d9e178aaae1d8ff',
          enTitle: 'Java Developer / Senior Java Developer',
          department: 'Backend',
          location: 'Singapore',
          enDescription: '<span style="color: rgb(63, 89, 154); font-size: 15px;">•	Design, Develop implement and maintain projects based on business requirements. <br> •	Prepare project documentation and test plans.</span>',
          enRequirements: '<span style="color: rgb(63, 89, 154); font-size: 15px;">• Solid Knowledge in Java Programming. <br> •	At least 3 years of development experience for Java projects. <br> • Experience in Spring, SpringBoot, Mybatis and other open source frameworks. <br> •	Experience in distributed application development with micro-service architecture. Familiar with SpringCloub, Dubbo and relevant frameworks. <br>•	Experience in Vue, React, HTML5 and other relevant front end development skills.<br>•	Experience in Oracle, MYSQL and other relevant database systems.<br>•	Knowledge of using Power Designer modelling tool will be a plus.<br>•	Good programming habits, documentation and project management.  Ability of analyzing and application design based on user requirements.<br>•	Strong responsibility, excellent communication and teamwork skills.<br>•	Preferable Singaporean.<br>•	Ability to speak/write Chinese (to liaise with Chinese-speaking associates). <br><br><b>*** You may email resume to :  carol.wee@aezypay.com</b></span>',
          postingUrl: 'https://www.jobstreet.com.sg/en/job/7355564/sources/2'
        },
        {
          id: '3bc1b58a588d285c',
          enTitle: 'Product Manager',
          department: 'Project',
          location: 'Singapore',
          enDescription: '<p data-v-4c0f062e="" class="paragraph mb60" style="color: rgb(63, 89, 154); font-size: 15px;">•	Lead the ideation, technical development and launch of innovative products. <br>•	Involve in planning and implementing channel strategies with the General Manager and Business Manager<br>•	Establish share vision across the company by building consensus on priorities leading to product execution<br>•	Designed according to business needs, determine system, product development functions and provide technical solutions for the project<br>•	Day-to-day management of product and development<br>•	Regularly accumulate knowledge and participate in the technical sharing and drive together with a team of developer, engineers and designers<br>•	Integrate usability studies, research and market analysis into product requirements to enhance user satisfaction<br>•	Define and analyze metrics that inform the success of products<br>•	Understand technology strategic and competitive position and deliver products that are recognized best in the industry<br>•	Responsible for system design and follow product procedures perfectly<br>•	Write technical detailed design documents<br>•	Identify, evaluate and resolve major technical risks and problems in the product<br>•	Complete development tasks on time according to company needs<br>•	Responsible for key brand product and inventory management<br>•	Forecasting and proposing of product performance<br>•	Gathering of market intelligence on new products, industry trends, competitive development and constant evaluation of potential new product<br>•	Cultivation of strong rapport with brands and partners</p>',
          enRequirements: '<p data-v-4c0f062e="" class="paragraph mb60" style="color: rgb(63, 89, 154); font-size: 15px;">•	Diploma, degree or recognized degree with minimum 2 years of experience portfolio management <br>•	Preferably have experience working with Fintech knowledge <br>•	Strong interpersonal and communication skills, both oral and written in English and Chinese <br>•	Good team player with positive attitude <br>•	Ability to multi-task and work under pressure and independently <br>•	Excellent project management ability with the strong organization skills in setting priorities and being able to work independently as well as a team in a driven environment <br>•	Ability to speak or write Chinese (to liaise with Chinese-speaking associates). <br><br><b>*** You may email resume to :  carol.wee@aezypay.com</b></p>',
          postingUrl: 'https://www.jobstreet.com.sg/en/job/7254610/sources/2'
        },
        {
          id: '3bc1b58a588d285c',
          enTitle: 'iOS / Android Engineer',
          department: 'Mobile',
          location: 'Singapore',
          enDescription: '<p data-v-4c0f062e="" class="paragraph mb60" style="color: rgb(63, 89, 154); font-size: 15px;">•	Design, build and extend new/existing products, platforms and features on Android/iOS platform. <br>•	Deliver well documented and readable source code on time based on project timeline. <br>•	Provide support and troubleshoot for any bugs reported and deliver fixes. <br>•	Enhance products performance by using latest technologies and improve quality of source code for better user experience. </p>',
          enRequirements: '<p data-v-4c0f062e="" class="paragraph mb60" style="color: rgb(63, 89, 154); font-size: 15px;">•	Minimum qualification of Degree in Information Technology, Computer Science and related areas. <br>•	At least 2 years of work experience in Android/iOS development, design and implementation. <br>•	Familiar with Android/iOS SDK & API <br>•	Familiar with Objective C/JAVA language. Knowledge of Kotlin/Swift will be an advantage. <br>•	Familiar with Android/IOS UI components, system, framework and common 3rd party libraries. <br>•	Willing to learn and apply new technologies. <br>•	Good communication and documentation skill. <br>•	Problem solving skills, highly passionate, team players & high EQ <br>•	Preferable Singaporean and able to speak/write Chinese (to liaise with Chinese associates) <br><br><b>*** You may email resume to :  carol.wee@aezypay.com</b></p>',
          postingUrl: 'https://www.jobstreet.com.sg/en/job/7407050/sources/2'
        },
        {
          id: '3bc1b58a588d285c',
          enTitle: 'IT Support',
          department: 'Infrastructure',
          location: 'Singapore',
          enDescription: '<p data-v-4c0f062e="" class="paragraph mb60" style="color: rgb(63, 89, 154); font-size: 15px;">•	Provide technical support to end-users remotely, answer user&#39;s questions of both hardware and software issues. <br>•	Provide support to remote teams and act as the bridge for any testing / troubleshooting issues with primary supported offices. <br>•	Prepare technical report on support issues, providing feedback and recommendations. <br>•	Responsible for documenting process, practices, solutions and troubleshooting procedures.</p>',
          enRequirements: '<p data-v-4c0f062e="" class="paragraph mb60" style="color: rgb(63, 89, 154); font-size: 15px;">•	Diploma / Degree in Information Technology or equivalent certificate(s) <br>•	Familiar with operating systems and web browser, include network settings and daily operations. With strong troubleshooting and problem-solving skills. <br>•	With technical support experience in block chains or financial payment systems are preferred. <br>•	Salary base on the experience and qualification. <br><br><b>*** You may email resume to :  carol.wee@aezypay.com</b></p>',
          postingUrl: 'https://www.jobstreet.com.sg/en/job/7407050/sources/2'
        },
      ]
    };
  },
  methods: {
    onDelete(id) {
      console.log("deleting id", id);
      this.deletePosting(id);
    },
    async getPostings(department = null, location = null) {

      if (department == null && location == null) {
        await axios.get("http://testdeployd.aezypay.com/departments").then(res => (department = res.data[0].id));
        await axios.get("http://testdeployd.aezypay.com/location").then(res => (location = res.data[0].id));  
        document.getElementById("select-department").selectedIndex = "1";
        document.getElementById("select-location").selectedIndex = "1";
        $('#select-department').trigger('change');
        $('#select-location').trigger('change');
      }

      await axios.get(
        `http://testdeployd.aezypay.com/postings?department=${department}&location=${location}`).then(res => {(this.postings = res.data)});
    },
    async deletePosting(id) {
      try {
        let res = await axios({
          url: `http://testdeployd.aezypay.com/postings/${id}`,
          method: "delete",
          withCredentials: true
        });

        //TODO: success msg + refresh page/call getpostings
        this.getPostings();
        $('.modal-backdrop').remove();
      } catch (err) {
        console.log(err);
      }
    }
  },
  created() {
    //this.getPostings(null, null);
  }
};
</script>

<style lang="scss" scoped>
.posting-accordian {
  .accordion {
    border-radius: 30px;
    -webkit-box-shadow: 0 0 15px rgba(#333, 0.0625);
    box-shadow: 0 0 15px rgba(#333, 0.0625);
    margin-bottom: 120px;
    overflow: hidden;

    .btn:not(:disabled):not(.disabled):active:focus,
    .btn[data-toggle="collapse"] {
      &:active,
      &:hover,
      &:focus {
        box-shadow: none;
        text-decoration: none;
        outline: none;
      }
    }

    .btn-link:active {
      box-shadow: none;
    }

    .card-header-description {
      display: inline-block;
      font-size: 14px;
      line-height: 18px;
      position: relative;
      top: -3px;

      .department {
        margin-top: 6px;
        font-weight: 900;
        color: #707ca6;
        margin-bottom: 0;
      }

      .location {
        color: #707ca6;
        font-weight: 300;
        margin-bottom: 0;
      }
    }

    .icon {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-left: 60px;
      margin-right: 15px;
      position: relative;
      top: -12px;
    }
    .func-btn {
      position: relative;
      top: -12px;
      margin-right: 10px;

      &.edit {
        background: url("../../assets/img/admin-career/edit.svg") no-repeat,
          #3f599a;
        background-position: 50.5% 50%;
        background-size: 39%;
      }
      
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }

    .card {
      border: none;

      .card-body {
        padding: 33px;
      }

      .card-header,
      .card-body {
        border-bottom: 1px solid #f1f1f2;
      }

      &:not(:last-child) .card-header {
        margin-bottom: 0;
      }
    }

    .card-header {
      background-color: white;
      padding-top: 9px;
      padding-bottom: 2px;
    }

    .title {
      font-size: 24px;
      font-weight: 400;
      color: #668ed6;
      margin-bottom: 0;
    }

    .secondary-title {
      font-size: 20px;
      color: #3f599a;
      margin-bottom: 15px;
    }

    .paragraph {
      color: #3f599a;
      font-size: 15px;
    }

    .list-requirements {
      color: #3f599a;
      font-size: 15px;
      padding: 0;
      list-style-type: none;
      margin-bottom: 60px;
    }
  }

  .plus {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
  }
  [aria-expanded="true"] .plus {
    transform: rotate(1035deg);
  }
  .no-open-position {
    height: 450px;
    margin-top: 20px;
    margin-bottom: 100px;
  }
}
</style>

<template>
  <div class="confirmation-modal">
    <button
      type="button"
      class="btn btn-primary btn-confirm"
      :class="`btn-${type}`"
      data-toggle="modal"
      :data-target="`#${id}`"
    >{{ btnName }}</button>

    <!-- Modal -->
    <div
      class="modal fade"
      :id="id"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-html="content"></div>
          <div class="modal-footer">
            <button type="button" class="btn" data-dismiss="modal">{{ secondaryBtnText }}</button>
            <button
              type="button"
              class="btn btn-primary"
              @click="$emit('submit')"
            >{{ primaryBtnText }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: {
    id: String,
    type: String,
    btnName: String,
    title: String,
    content: String,
    primaryBtnText:{
      type: String, 
      default: "Submit"
    },
    secondaryBtnText: {
      type: String, 
      default: "Cancel"
    }
  },
  data: function() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/scss/modal';

.confirmation-modal {
  display: inline-block;
  .btn-delete {
    background: url("../../assets/img/admin-career/delete.svg") no-repeat,
      #3f599a;
    background-position: 50.5% 50.55%;
    background-size: 36%;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: none;
    outline: none;
  }
}
</style>
